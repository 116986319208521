.article-header-skeleton {
  min-height: 450px;
}
.resto-image-skeleton > span {
  position: absolute;
  display: inline-block;
  bottom: -14px;
  left: calc(2rem - 4px);
  background: #fff;
  padding: 0.3rem 0.5rem 0.5rem 0.5rem;
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 700;
  letter-spacing: 1px;
  border: 4px solid #fff;
  border-radius: 2px;
  width: 80px;
}

.resto-image-skeleton > span i {
  opacity: 0.25;
}

.resto-image-skeleton {
  position: relative;
  padding-bottom: 60.25%;
}

.resto-info-skeleton {
  padding: 2rem;
  padding-bottom: 5rem
}

.resto-info-skeleton-h4 {
  margin-bottom: 0.5rem;
  height: 1.1rem;
}

.resto-desc-skeleton {
  margin: 1rem 0;
}

.resto-desc-skeleton span {
  display: block;
  height: 8px;
  margin-bottom: 10px;
  background: linear-gradient(45deg, #dddddd, transparent);
}

.btn-skeleton {
  border: 2px solid #eae5e4;
  width: 100px;
  height: 44px;
  position: absolute;
  bottom: 2rem;
}

.skeleton {
  background-color: #a8a19f;
  animation-name: skeleton-loading;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes skeleton-loading {
  from {
    background-color: #a8a19f;
  }
  to {
    background-color: #d4cbc7;
  }
}
