@keyframes lds-hourglass {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}

@media all and (max-width: 1139px){
  .article-cards > div {
    width: 100%;
  }
}

@media all and (max-width: 1059px){
  #article-image {
    border: 0;
  }
  .article-content > div.article-cards {
    padding: 0 1rem;
    max-width: 100%;
  }
}

@media all and (max-width: 991px){
  #resto-list-items {
    grid-template-columns: repeat(2, 1fr);
  }

  .article-cards > div {
    padding: 0 1rem 1rem;
  }

  .article-cards label {
    margin-left: -1rem;
    padding: 0.5rem 1rem;
    margin-top: 1rem;
  }
}

@media all and (max-width: 767px){
  .article-content > div.article-cards {
    grid-template-columns: 1fr 1fr;
  }
  .article-cards > div:nth-child(3) {
    grid-row-start: 2;
    grid-row-end: 3;
  }
  .article-cards > div:nth-child(2) {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 2;
    grid-column-end: 3;
  }
  .article-cards > div:nth-child(4) {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 2;
    grid-column-end: 3;
  }
  .article-content > p {
    margin: 0;
    padding: 1rem;
    padding-bottom: 4rem;
  }
}

@media all and (max-width: 659px){
  #site-header {
    padding: 0
  }

  #menu-list-block {
    position: fixed;
    left: 0;
    top: 59px;
    width: 100%;
    max-height: 0;
    transition: all 0.25s ease-in-out;
    background: #644f47;
    overflow: hidden;
  }

  #menu-list-block.open {
    max-height: 100%;
  }

  #menu-list {
    width: 100%;
  }

  #menu-list li {
    display: block;
  }

  .menu-link {
    display: block;
  }

  .menu-link.active {
    color: #fff;
    background: linear-gradient(45deg, #463832, #856a5f);
  }

  .menu-link.active::after, .menu-link:hover::after {
    display: none;
  }

  #menu-toggler {
    display: block;
    padding: 0 0.5rem;
    background: transparent;
    border: 2px solid rgba(255,255,255,0.1);
    color: #fff;
    font-size: 2rem;
    min-width: 44px;
    min-height: 44px;
    border-radius: 2px;
    margin-right: 1rem;
    padding-bottom: 4px;
  }

  #resto-list {
    padding: 3rem 1rem;
  }

  .resto-info {
    padding: 1rem;
    padding-bottom: 4rem;
  }

  #resto-list-items .resto-image > span {
    left: 1rem;
  }

  .resto-info a {
    bottom: 1rem;
  }

  .article-content > p {
    margin: 1rem 0;
    padding: 1rem;
    padding-bottom: 3rem;
  }

}

@media all and (max-width: 514px){

  #article-image {
    margin-bottom: 0;
  }

  .article-header h2 {
    font-size: 2.5rem;
  }

  .article-header h2 i {
    margin-left: -56px;
  }

  .article-header p {
    font-size: 1rem;
  }

  h2.article-title {
    font-size: 2rem;
  }

  #resto-list-items {
    grid-template-columns: 1fr;
  }

  .article-content > div.article-cards {
    grid-template-columns: 1fr;
    gap: 0;
    padding: 0;
  }
  .article-cards > div {
    padding: 0 1rem 1rem;
  }

  .article-cards > div:first-child, .article-cards > div:nth-child(2) {
    grid-column-start: 1;
    grid-column-end: 2;
    padding-bottom: 0;
  }

  .article-cards > div:nth-child(3) {
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .article-cards > div:nth-child(4) {
    grid-row-start: auto;
    grid-row-end: auto;
    grid-column-start: 1;
    grid-column-end: 2;
  }

  #review-form {
    padding: 1rem;
  }

  #query {
    width: 100%;
  }
}

@media all and (max-width: 320px){
  .article-header h2 {
    font-size: 2.3rem;
  }
  h2.article-title {
    font-size: 2rem;
  }
}
