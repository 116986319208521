* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 16px;
  background: #eee;
}

body {
  font-size: 1rem;
  font-family: 'Open Sans', sans-serif;
  background: #eee;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat', sans-serif;
}

h3 {
  text-align: center;
  font-size: 1.5rem;
}

img {
  width: 100%;
  display: block;
}

button {
  cursor: pointer;
}

li {
  list-style: none;
  position: relative;
}

.btn {
  padding: 0.6rem 1rem;
  border: 2px solid #9e7d70;
  display: inline-block;
  border-radius: 2px;
  font-weight: 600;
  min-height: 44px;
  font-size: 0.85rem;
  transition: all 0.25s ease-in-out;
  background: transparent;
  color: #7e6358;
}

.btn:hover {
  background: #6e564c;
  color: #fff;
}

#skip-to-content {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
}

#skip-to-content:focus {
  background-color: #bb826c;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  clip-path: none;
  color: #fff;
  display: block;
  font-size: 0.875rem;
  font-weight: 700;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
}

#primary[tabindex="-1"]:focus {
	outline: 0;
}

main {
  min-height: 100vh;
  background: #dbd2cf;
}

.text-center {
  text-align: center;
}

#site-header {
  background: #644f47;
  position: fixed;
  width: 100%;
  z-index: 9;
  padding: 0 1rem;
}

#header-block {
  display: flex;
  max-width: calc(1140px + 2rem);
  margin: auto;
  height: 59px;
}

#site-branding {
  margin-right: auto;
  align-self: center;
}

#site-title a {
  display: inline-block;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  padding: 1rem;
  letter-spacing: 1px;
  font-size: 1.4rem;
}

#site-title a span {
  color: #ffd0be;
}

#navbar {
  align-self: center;
}

.menu-link {
  padding: 1rem;
  display: inline-block;
  color: rgba(255,255,255,0.7);
  font-family: 'Montserrat', sans-serif;
  position: relative;
  transition: all 0.25s ease-in-out;
}

.menu-link.active {
  color: rgba(255,255,255,0.85);
}


.menu-link.active::after, .menu-link:hover::after {
  content: "";
  position: absolute;
  width: calc(100% - 2rem);
  height: 3px;
  background: linear-gradient(45deg, #ffd0be, #ffffff);
  left: 1rem;
  bottom: 10px;
}

.menu-link:hover::after {
  opacity: 0.25;
}
.menu-link.active::after {
  opacity: 1;
}

.menu-link:hover {
  color: #ffd0be;
}

#menu-toggler {
  display: none;
}

#menu-list {
  list-style: none;
  display: table;
}

#menu-list li {
  display: table-cell;
  vertical-align: middle;
}

.article-header {
  display: flex;
  /* background: #000 url(../public/images/hero-image_2.jpg) center center;
  background-size: cover; */
  min-height: 450px;
  color: #fff;
  position: relative;
  overflow: hidden;
}

.article-header h2 i {
  transform: rotate(-45deg);
  position: relative;
  right: -42px;
  margin-left: -64px;
  top: -27px;
}

header.article-header {
  min-height: 540px;
  background: #000;
}

.article-header::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(45deg, #211714, #402d26b5);
  z-index: 1;
}

header.article-header::before {
  background: linear-gradient(45deg, #211714d9, #402d26e0);
}

.article-header > div {
  max-width: 520px;
  margin: auto;
  text-align: center;
  align-self: center;
  position: relative;
  z-index: 2;
  padding: 1rem;
  padding-top: 4rem;
}

.article-header h2 {
  font-size: 3.2rem;
  letter-spacing: 1px;
}

.article-header h2 span {
  color: #ffd0be;
}

.article-header p {
  font-size: 1.5rem;
}

#article-image {
  padding-bottom: 62%;
  position: relative;
  z-index: 1;
  margin-top: -12rem;
  border: 40px solid #dbd2cf;
  border-bottom: 0;
  margin-bottom: 1rem;
}

.section-header > div {
  height: 4px;
  background: #cf937c;
  width: 50px;
  margin: 1rem auto;
  margin-bottom: 3rem;
  position: relative;
}

.section-header > div::after {
  content: "";
  position: absolute;
  top: 1px;
  left: -20px;
  height: 2px;
  background: #cf927c70;
  width: 90px;
}

#resto-list {
  background: #dbd2ce;
  padding: 4rem 2rem;
}

#resto-list-items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  max-width: 1140px;
  margin: auto;
}

#resto-list-items article {
  position: relative;
  background: #fff;
}

#resto-list-items .resto-image {
  position: relative;
  padding-bottom: 60.25%;
  overflow: hidden;
}

#resto-list-items .resto-image > span {
  position: absolute;
  display: inline-block;
  bottom: -14px;
  left: calc(2rem - 4px);
  background: #fff;
  padding: 0.3rem 0.5rem 0.5rem 0.5rem;
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 700;
  letter-spacing: 1px;
  border: 4px solid #fff;
  border-radius: 2px
}

#resto-list-items article:hover img {
  transform: scale(1.1);
}

#resto-list-items img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  transition: all 0.25s ease-in-out;
}

.resto-info {
  padding: 2rem;
  padding-bottom: 5rem
}

.resto-info h4 {
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
}

.resto-info a {
  position: absolute;
  bottom: 2rem;
}

.resto-desc {
  margin: 1rem 0;
  color: #6e6e6e;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
}

.resto-rating {
  --percent: calc(var(--rating) / 5 * 100%);
  display: inline-block;
  font-size: 1rem;
  line-height: 1;
  font-family: "FontAwesome";
}

.resto-rating::before {
  content: '\f005\f005\f005\f005\f005';
  letter-spacing: 3px;
  background: linear-gradient(90deg, #eea78b var(--percent), #ddd var(--percent));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

header.article-header > div {
  padding-top: 0;
  margin-top: 5rem;
  max-width: 960px;
  margin-bottom: 12rem;
}

.article-header img {
  width: 100%;
  position: absolute;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
header.article-header img {
  filter: blur(10px);
}

.article-header p.resto-rating {
  margin-bottom: 1rem;
}

h2.article-title {
  font-size: 2.8rem;
  margin-bottom: 1.5rem;
}

.article-content {
  max-width: 1140px;
  margin: auto;
}

.article-content img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.article-content > p {
  max-width: 740px;
  margin: 2rem auto;
  font-size: 1.1rem;
  color: #4c4c4c;
  padding-bottom: 2rem;
}

.article-content > div {
  max-width: 1060px;
  margin: auto;
}

.review-card {
  padding: 4rem 1rem;
  background: #e6dfdc;
}

.review-card > div {
  max-width: 740px;
  margin: auto;
}

.flex {
  display: flex;
}

.article-content > div.article-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 2fr;
  gap: 1rem;
  max-width: 980px;
}

.article-cards > div {
  background: #fff;
  padding: 0 2rem 2rem;
}

.article-cards > div:nth-child(2) {
  grid-row-start: 2;
  grid-row-end: 3;
}

.article-cards > div:nth-child(3) {
  grid-row-start: 1;
  grid-row-end: 3;
}

.article-cards > div:nth-child(4) {
  grid-row-start: 1;
  grid-row-end: 3;
}

.article-cards label {
  display: inline-block;
  font-weight: 600;
  letter-spacing: 1px;
  margin-bottom: 1rem;
  background: linear-gradient(45deg, #644f47, #8d614f);
  margin-left: -2rem;
  padding: 0.5rem 2rem;
  color: #FFF;
  margin-top: 2rem;
  font-family: 'Montserrat', sans-serif;
  font-size: 0.8rem;
  opacity: 0.8;
}

.article-cards p span {
  font-family: 'Montserrat';
  display: block;
  margin-bottom: 10px;
}

.article-cards .flex {
  margin-bottom: 0.5rem;
}

.article-cards .flex > div:first-child {
  padding-right: 5px;
}

.article-cards .flex > div:first-child i {
  color: #cb927b;
}

.article-cards li {
  padding-left: 1rem;
  margin-bottom: 0.25rem;
}

.article-cards li::before {
  content: "\f105";
  font-family: 'FontAwesome';
  position: absolute;
  top: 3px;
  left: 2px;
  color: #cb927b;
}

#footer {
  padding: 2rem;
  text-align: center;
  background: #3f2e27;
  color: rgba(255,255,255,0.75);
}

#footer strong {
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 1px;
}
#footer strong span {
  color: #ffd0be;
}

#loader {
  padding: 6rem;
  min-height: 400px;
  text-align: center;
}

#like-button, #review-form button {
  padding: 1rem;
  min-height: 44px;
  margin-top: 1rem;
  border: 2px solid #9a8075;
  font-size: 1.2rem;
  color: #6f4f42;
  background: #fff7f4;
}

#review-form {
  margin-top: 2rem;
  background: #d6ccc8;
  padding: 2rem;
}

#review-form > div {
  margin-bottom: 1rem;
}

#review-form h4 {
  margin-bottom: 1rem;
}

#review-form label {
  display: block;
  font-weight: 600;
  opacity: 0.6;
}

#review-error > div {
  display: flex;
  color: #80521f;
  font-weight: 600;
  margin-bottom: -1rem;
}

#review-error > div > span:first-child {
  padding-right: 10px;
}

#review-form input, #review-form textarea, #query {
  width: 100%;
  padding: 1rem;
  font-size: 1.1rem;
  border: 1px solid #c0b2ae;
  box-shadow: inset 0 0 15px #888;
  border-radius: 2px;
  font-family: "Open Sans", sans-serif;
}

#search-fav-container {
  padding: 1rem;
}

#query {
  display: block;
  max-width: 450px;
  margin: 4rem auto 0rem;
}

.lds-hourglass {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-hourglass:after {
  content: " ";
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 8px;
  box-sizing: border-box;
  border: 32px solid #fff;
  border-color: #644f47 transparent #ffd0be transparent;
  animation: lds-hourglass 1.2s infinite;
}

.lazyload {
  opacity: 0;
}

#resto-list-items > p {
  grid-column-end: 4;
  grid-column-start: 1;
  text-align: center;
}
